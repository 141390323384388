<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <modal-add-category
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-add-category-active.sync="isModalAddCategoryActive"
      :store-module-name-service-area="STORE_MODULE_NAME_SERVICE_AREA"
      :show.sync="show"
      :data="modalAddCategoryData"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-row>

        <b-col cols="3">

          <b-card-text>{{ $t("Category") }}</b-card-text>

          <div class="d-flex align-items-center justify-content-end">

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="mb-2"
              @click="modalAddCategoryEdit({})"
            >
              {{ $t("Add") }}
            </b-button>
            <b-button
              v-if="categoryData._id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              size="sm"
              class="mb-2 ml-1"
              @click="modalAddCategoryEdit(categoryData)"
            >
              {{ $t("Edit") }}
            </b-button>
            <b-button
              v-if="categoryData._id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              size="sm"
              class="mb-2 ml-1"
              @click="deleteData(categoryData._id)"
            >
              {{ $t("Delete") }}
            </b-button>
          </div>

          <div v-if="respData.length>0">
            <b-card
              class="mb-0"
            >
              <b-tabs
                pills
                vertical
                nav-wrapper-class="w-100"
              >
                <b-tab
                  v-for="(item,index) in respData"
                  :key="index"
                  :active="item._id === categoryData._id ? true:false"
                  :title="showFromCurrentLanguage(item.name)"
                  @click="selectCategory(item)"
                />
              </b-tabs>
            </b-card>
          </div>
          <div v-else>
            <p class="text-center">
              {{ $t('Please add category') }}
            </p>
          </div>

        </b-col>
        <b-col cols="9">
          <div v-if="categoryData._id">
            <coupon />
          </div>
          <div v-else>
            <b-card>
              <p class="text-center">
                {{ $t('Please add category') }}
              </p>
            </b-card>

          </div>
        </b-col>
      </b-row>

    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BButton, BCardText, BRow, BCol,
  BImg,
  BTab,
  BTabs,
  BAvatar,
  BFormInput, BOverlay,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/hero/coupon'
import storeModuleServiceArea from '@/store/services/hero/serviceArea'
import ModalAddCategory from './components/ModalAddCategory.vue'
import Coupon from './Coupon.vue'

const STORE_MODULE_NAME = 'coupon'
const STORE_MODULE_NAME_SERVICE_AREA = 'serviceArea'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BTab,
    BTabs,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BOverlay,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    ModalAddCategory,
    Coupon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      isModalAddCategoryActive: false,
      modalAddCategoryData: {},
      categoryData: {},
      show: false,
      perPageOptions,
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respDataCategory != null ? store.state[STORE_MODULE_NAME].respDataCategory.data : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respDataCategory != null ? store.state[STORE_MODULE_NAME].respDataCategory.max : 0
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    if (!store.hasModule(STORE_MODULE_NAME_SERVICE_AREA)) store.registerModule(STORE_MODULE_NAME_SERVICE_AREA, storeModuleServiceArea)
    const obj = {
      currentPage: 1,
      pageSize: 0,
      searchQuery: '',
    }

    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/getCategory`, obj)
    // eslint-disable-next-line no-unused-vars
      .then(result => {
        if (result.data.data.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.selectCategory(result.data.data[0])
        }
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME_SERVICE_AREA}/get`, {
        currentPage: 0,
        pageSize: 0,
        searchQuery: '',
      })
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME_SERVICE_AREA)) store.unregisterModule(STORE_MODULE_NAME_SERVICE_AREA)
  },
  setup() {
    return { STORE_MODULE_NAME, STORE_MODULE_NAME_SERVICE_AREA }
  },
  methods: {
    selectCategory(value) {
      this.categoryData = value
      this.$router.push({ path: `/hero/privilege/coupon/${value._id}` }).catch(e => console.log(e))
    },
    modalAddCategoryEdit(item) {
      this.modalAddCategoryData = item
      this.isModalAddCategoryActive = true
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/deleteCategory`, id)
              .then(result => {
                this.show = false
                this.categoryData = {}
                if (this.respData.length > 0) {
                  // eslint-disable-next-line prefer-destructuring
                  this.categoryData = this.respData[0]
                }
                console.log('fetch Success : ', result)
              }).catch(error => {
                this.show = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .v-select-custom {
    display: inline-block;
    width: 50;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .b-col-custom {
    margin-top: 0.5rem;
  }
  </style>
